import React from 'react'

import ButtonField from './form/ButtonField'
import { HOME_SCREEN } from '../../constant'

const ReadyToNextStep = () => {
  return (
    <div className="helpRepairContainer">
      <div className="cashOffer flexCenter">
        <div className="flex-column cashOfferAnimation">
          <div className="text-center mainTitle px-2">
            Ready To Take The Next Step?
          </div>
          <div className="cashOfferSubtitle">
            Sell Your Home To Tarek El Moussa Today!
          </div>
          <div className="d-flex justify-content-center mt-1">
            <ButtonField className="LearnMoreBtn" name="GET STARTED" to={HOME_SCREEN} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReadyToNextStep