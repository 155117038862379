import React from 'react'

import Layout from "../components/layout";
import AboutUs from "../components/aboutUs";

const AboutUsComp = () => {
  return (
    <Layout title="about-us">
      <AboutUs />
    </Layout>
  );
}

export default AboutUsComp;