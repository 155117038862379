import React from 'react'
import Img from "gatsby-image"

import BackgroundSection from "./bgImage"

const Hero = ({ menImage, nodes }) => {

  return (
    <BackgroundSection className="bg-image-cover" alt="home-image">
      <div className="topSection" style={{}}>
        <div className="introContent">
          <h1 className="title">{nodes.title}</h1>
          {/* <div className="subTitle">{nodes.subTitle}</div> */}
        </div>
        <div className='manImageCrop' >
          <Img
            fluid={menImage}
            className="manImage"
            width={110}
            height={70}
            alt="rating"
          />
        </div>
      </div>
    </BackgroundSection>
  )
}

export default Hero;