import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown";

import ReadyToNextStep from "./common/readyToNextStep";
import Hero from './common/heroSection'

const AboutUs = () => {
  const aboutUsData = useStaticQuery(graphql`
    query {
      menImage: file(relativePath: { eq: "menImageCrop.png" }) {
        childImageSharp {
          fluid(quality: 0, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allStrapiAboutUs {
        nodes {
          Title
          Description
        }
      }
    }
  `)
  const { nodes } = aboutUsData.allStrapiAboutUs
  const menImage = aboutUsData.menImage.childImageSharp.fluid

  return (
    <div className='faq aboutUs'>
      <Hero menImage={menImage} nodes={{ title:nodes[0].Title, }} />
      <ReactMarkdown className="description">
        {nodes[0].Description}
      </ReactMarkdown>
      <ReadyToNextStep />
    </div>
  )
}

export default AboutUs
